import React, { memo, useContext, useEffect } from 'react';
import useObserveVisibility from 'App/Game/useObserveVisibility';
import Modal from 'App/React/_UI/Modal/Modal';
import { updateUrlHash } from 'App/Helpers';
import { LanguageContext } from '../../providers';
import useTermsAndPrivacyContent from '../../hooks/useTermsAndPrivacyContent';
import config from '../../config';
import Hero from './components/Hero';
import HowPlay from './components/HowPlay';
import WhyFun from './components/WhyFun';
import GameDescription from './components/GameDescription';
import Footer from './components/Footer';

const bacanagolEventConfigs = [
    {
        id: 'how_play_visible',
        gameGaEvent: 'HP:HowPlayVisible',
        gaEvent: 'hp_how_play_visible',
    },
    {
        id: 'why_fun_visible',
        gameGaEvent: 'HP:WhyFunVisible',
        gaEvent: 'hp_why_fun_visible',
    },
    {
        id: 'game_description_visible',
        gameGaEvent: 'HP:GameDiscriptionVisible',
        gaEvent: 'hp_game_discription_visible',
    },
    {
        id: 'footer_visible',
        gameGaEvent: 'HP:FooterVisible',
        gaEvent: 'hp_footer_visible',
    },
];

const Landing = ({ updatePhase, showPushPhase, showPwaPhase, gameGA, ga }) => {
    const { locale, path } = useContext(LanguageContext);
    // add third parameter true to useTermsAndPrivacyContent in order to activate fetch local terms and privacy files logic
    const {
        fetchLocal,
        isModalOpen,
        modalContent,
        showTermsUse,
        hideTermsUse,
    } = useTermsAndPrivacyContent(config.modalsUrl, locale);
    useObserveVisibility(bacanagolEventConfigs, gameGA, ga);

    useEffect(() => {
        updateUrlHash('1');

        const languageChanged = localStorage.getItem('languageChanged');
        languageChanged === 'true' &&
            localStorage.removeItem('languageChanged');
    }, []);

    const playButtonClickHandler = () => {
        updatePhase(showPushPhase ? 'push' : showPwaPhase ? 'pwa' : 'game');
        gameGA?.sendDesignEvent('HP:PlayButton');
        ga?.basicGaEvent('hp', 'play_button');
    };

    return (
        <>
            <main>
                <Hero
                    lang={locale}
                    onBtnClick={playButtonClickHandler}
                    gameGa={gameGA}
                    ga={ga}
                />
                <HowPlay lang={locale} onBtnClick={playButtonClickHandler} />
                <WhyFun lang={locale} onBtnClick={playButtonClickHandler} />
                <GameDescription lang={locale} />
            </main>
            <Footer
                lang={locale}
                showTermsUse={showTermsUse}
                path={path}
                fetchLocal={fetchLocal}
            />
            {isModalOpen && (
                <Modal
                    lang={locale}
                    title={modalContent ? modalContent.title : ''}
                    content={modalContent ? modalContent.content : ''}
                    onClose={hideTermsUse}
                />
            )}
        </>
    );
};

export default memo(Landing);
