export default function handleFetchErrors(response) {
    if (!response.ok) {
        return response
            .json()
            .then((res) => {
                const error = {
                    status: res.status || 'unknown',
                    message: res.message || 'An unknown error occurred',
                    code: res.error_code || null,
                };

                if (error.status === 'error') {
                    throw error;
                }

                if (error.status === 'Bad Request') {
                    error.message = 'SharpStar responded with "Bad Request"';
                    throw error;
                }

                error.message = 'Fetch failed with unknown status';
                throw error;
            })
            .catch(() => {
                throw {
                    status: 'unknown',
                    message: 'Fetch failed, unable to parse error response',
                    code: null,
                };
            });
    }
}
