export const getRandomElement = (max, prev) => {
    const newNum = Math.floor(Math.random() * max);

    return newNum === prev ? getRandomElement(max, prev) : newNum;
};

export const getUrlParamsObj = (url, pageType = 'gambling') => {
    const urlParams = new URLSearchParams(url);
    const urlParamsObj = {};
    const paramNames = ['refid1', 'refid2', 'refid3'];

    const getParamValue = (name) => {
        const value = urlParams.get(name) || '0';

        if (value !== '0') {
            localStorage.setItem(name, value);
        } else if (localStorage.getItem(name)) {
            return localStorage.getItem(name);
        }

        return value;
    };

    const rid = urlParams.get('rid');

    if (pageType === 'gambling') {
        urlParamsObj['rid'] = rid || null;
        urlParamsObj['hash'] = urlParams.get('hash') || undefined;
    } else if (pageType === 'game') {
        if (rid) {
            urlParamsObj['rid'] = rid;
            localStorage.setItem('rid', rid);
        } else {
            urlParamsObj['rid'] = localStorage.getItem('rid') || null;
        }
    }

    paramNames.forEach((name) => {
        urlParamsObj[name] = getParamValue(name);
    });

    return urlParamsObj;
};

export const arrayFromNumber = (number) => {
    return Array.from({ length: +number }, (_, i) => i + 1);
};

export const setBodyOverflow = ({ overflow }) => {
    document.documentElement.style.setProperty('--overflow', overflow);
};

export const getSecondsDifference = (startDate, endDate) => {
    return +(Math.abs(endDate - startDate) / 1000).toFixed(2);
};

export const getKeyByValue = (object, value) => {
    return Object.keys(object).find((key) => object[key] === value);
};

export const capitalize = (str) => {
    return str[0].toUpperCase() + str.slice(1);
};

export const handleCacheControl = (url) => {
    if (url.match(/\.data/) || url.match(/\.bundle/)) {
        return 'must-revalidate';
    }
    if (url.match(/\.mp4/) || url.match(/\.wav/)) {
        return 'immutable';
    }
    return 'no-store';
};

export const getHash = () => {
    const hash = window.location.hash.substring(1);

    return hash.includes('?') ? hash.split('?')[0] : hash;
};

export const updateUrlHash = (value) => {
    const urlHash = window.location.hash.substring(1);

    if (!urlHash) return;

    const hashParts = urlHash.split('?');

    if (hashParts.length === 1) {
        // Check if the hash is not a valid phase value.
        // If it is then set the function parameter to the hash value
        if (!isNaN(+hashParts[0])) window.location.hash = value;
    } else {
        let hash;
        let refParams;
        hashParts.map((part) => {
            if (part.includes('refid')) {
                refParams = part;
            } else {
                hash = !isNaN(+part) ? value : part;
            }
        });

        window.location.hash = `${hash}?${refParams}`;
    }
};

export const flattenObject = (obj, parentKey = '', result = {}) => {
    for (let key in obj) {
        if (!Object.prototype.hasOwnProperty.call(obj, key)) continue;

        let propName = parentKey ? parentKey + '-' + key : key;

        // If the property is an object, recurse
        if (
            typeof obj[key] === 'object' &&
            obj[key] !== null &&
            !Array.isArray(obj[key])
        ) {
            flattenObject(obj[key], propName, result);
        } else {
            // Check if the value is falsy and skip it if it is
            if (obj[key]) {
                result[propName] = obj[key];
            }
        }
    }
    return result;
};

/**
 * Check if container DOM element is iframe element or it has iframe element
 *
 * @param element - <HTMLElement>(required)
 * @param tagName - string(optional)
 *
 * @return boolean
 */
export const hasAdElement = (element, tagName) => {
    if (element.tagName === tagName) {
        // If the element is the same as tagName, return true
        return true;
    } else {
        // Otherwise, check each child element recursively
        for (let i = 0; i < element.childElementCount; i++) {
            if (hasAdElement(element.children[i], tagName)) {
                // If any child element has the same element as tagName, return true
                return true;
            }
        }
    }
    // If no element the same as tagName is found, return false
    return false;
};

export const getUrlParamValue = (inputKey) => {
    const urlParams = new URLSearchParams(window.location.search);

    return urlParams.get(inputKey) || null;
};
