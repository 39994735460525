import React, { createContext, useCallback, useEffect, useState } from 'react';
import { canPromptForPushNotification } from 'App/Helpers';

const getPushPhaseViews = () => {
    return parseInt(localStorage.getItem('pushPhaseViews') || 0);
};

// checks if show push phase for user
const shouldShowPushPhase = () => {
    const canPromptForPush = canPromptForPushNotification();

    if (!canPromptForPush) return false;

    // If Notification.permission is 'default' but the local storage status is 'granted',
    // reset the local storage values to their initial states.
    const pushStatus = localStorage.getItem('pushStatus');

    if (canPromptForPush && pushStatus === 'granted') {
        localStorage.setItem('showPushPhase', 'true');
        localStorage.setItem('pushStatus', 'default');
        localStorage.setItem('pushPhaseViews', '1');

        return true;
    }

    return localStorage.getItem('showPushPhase') !== 'false';
};

// status can be 'default', 'called', 'granted' or 'denied'
const getPushStatus = () => {
    const status = localStorage.getItem('pushStatus');

    return status === 'granted' || status === 'denied' ? status : 'default';
};

const PushContext = createContext(null);

const PushNotificationProvider = ({ children }) => {
    const [showPushPhase, setShowPushPhase] = useState(() =>
        shouldShowPushPhase()
    );
    const [pushStatus, setPushStatus] = useState(() => getPushStatus());
    const [pushPhaseViews, setPushPhaseViews] = useState(() =>
        getPushPhaseViews()
    );
    const [pushPromptTotal, setPushPromptTotal] = useState(null);

    const updateShowPushPhase = useCallback(() => {
        const shouldShowPushPhase =
            (pushStatus === 'default' || pushStatus === 'called') &&
            pushPhaseViews < pushPromptTotal;

        setShowPushPhase(shouldShowPushPhase);
        localStorage.setItem('showPushPhase', shouldShowPushPhase.toString());
    }, [pushStatus, pushPhaseViews, pushPromptTotal]);

    useEffect(() => {
        !showPushPhase && localStorage.setItem('showPushPhase', 'false');
    }, [showPushPhase]);

    useEffect(() => {
        updateShowPushPhase();
    }, [pushPromptTotal, pushPhaseViews, pushStatus, updateShowPushPhase]);

    const updatePushPhaseViews = useCallback(() => {
        setPushPhaseViews((prevPushViews) => {
            const newPushViews = prevPushViews + 1;

            localStorage.setItem('pushPhaseViews', newPushViews.toString());
            return newPushViews;
        });
    }, []);

    const updatePushStatus = useCallback((pushStatus) => {
        setPushStatus(pushStatus);
        localStorage.setItem('pushStatus', pushStatus);
    }, []);

    return (
        <PushContext.Provider
            value={{
                pushStatus,
                showPushPhase,
                pushPhaseViews,
                setPushPromptTotal,
                setShowPushPhase,
                updatePushStatus,
                updatePushPhaseViews,
            }}>
            {children}
        </PushContext.Provider>
    );
};

export { PushNotificationProvider, PushContext };
