import UrlParams from 'App/Helpers/UrlParams';
import handleFetchErrors from '../Errors/handleFetchErrors';

const SharpStarAPI = ({ sharpstarApiUrl, source, campaignId }) => {
    const abortController = new AbortController();
    const { refid1, refid2, refid3 } = UrlParams;

    const defaultFetch = async (url, options) => {
        const fetchOptions = {
            ...options,
            signal: abortController.signal,
        };

        try {
            const response = await fetch(url, fetchOptions);

            await handleFetchErrors(response);

            return response.json();
        } catch (e) {
            if (e.name === 'AbortError') {
                console.warn(`Request to ${url} was aborted`);
            } else {
                console.error(`Error fetching ${url}:`, e);
            }
            throw e;
        }
    };

    return {
        getOffers: ({ hash, is_lead, rid }) => {
            const params = new URLSearchParams({
                campaign_id: campaignId,
                source,
                is_lead,
                refid1,
                refid2,
                refid3,
                rid,
                ...(hash && { hash }),
            });

            const url = `${sharpstarApiUrl}offer?${params.toString()}`;

            return defaultFetch(url, { method: 'GET' });
        },

        abort: () => abortController.abort(),
    };
};

export default SharpStarAPI;
